(function($) {

    var $body = $("body");

    $body.on('click', '[data-menu]', function(e) {
        e.preventDefault();
        var $trigger = $(this);
        var menuSelector = $trigger.data("menu");

        var $menu = $(menuSelector);
        if ($menu.is(".hidden")) {
            $menu.removeClass('hidden').attr('aria-hidden', false);
            $trigger.addClass("active");

            $body.one('click', function() {
                $menu.addClass('hidden').attr('aria-hidden', true);
                $trigger.removeClass("active");
            });
        }
        else {
            $menu.addClass('hidden').attr('aria-hidden', true);
            $trigger.removeClass("active");
        }
    });

    $body.on("click", ".collapsednav-toggler", function(e) {
        e.preventDefault();
        var $elem = $(this);
        var $appMain = $(".app-main");
        var $sidePanel = $elem.closest(".side-panel");

        $appMain.toggleClass("nav-collapsed");
        var title = ( $appMain.is(".nav-collapsed") ) ? 'Expand menu' : 'Collapse menu';
        if ( $elem[0].hasAttribute('data-tip') ) {
            // $elem.attr('data-tip', title);
        }
        else {
            $elem.attr('title', title);
        }

        // prevent hover from keeping side panel open when collapsing
        if ( $appMain.is(".nav-collapsed") ) {
            $sidePanel.addClass("collapsing");
            window.setTimeout(function() {
                $sidePanel.removeClass("collapsing");
            }, 500);
        }
    });

    // when a tooltip is attached to a toggle of some sort, we
    // often want to change the title (and tooltip text)
    // to match the new state
    $body.on("click", "[data-uk-tooltip]", function(e) {
        var title= $(this).attr('title');
        var $tooltip = $(".uk-tooltip.uk-active .uk-tooltip-inner");

        window.setTimeout(function() {
            $tooltip.html(title);
        }, 10);
    });


    // $(".side-panel-scroll").slimscroll({
    //     height: '100%'
    // });

    // $(".side-panel-content > .nav").slimscroll({
    //     height: '100%'
    // });


    var openNav = function() {
        $(".side-panel-nav").addClass("active open");
        $("body").addClass("side-panel-mobile-open");
        $("#page-mask").addClass("open");
    };
    var closeNav = function() {
        $(".side-panel-nav").removeClass("active open");
        $("body").removeClass("side-panel-mobile-open");
        $("#page-mask").removeClass("open");
    };

    $(".toggle-side-panel-btn").on("click", function(e) {
        e.preventDefault();

        if ( $(".side-panel-nav").is(".open") ) {
            closeNav();
        }
        else {
            openNav();
        }
    });
    $(".side-panel").on("click", ".side-panel-close", function(e) {
        e.preventDefault();
        closeNav();
    });
    $("#page-mask").on("click", function() {
        closeNav();
    });

})(jQuery);

(function($) {

    // AccordionNav
    var $nav = $(".side-panel-nav ul.nav");
    var $lists = $nav.find("ul").parent("li");
    var slideTime = 250;

    // $lists.append('<i class="material-icons icon-has-ul">arrow_drop_down</i>');
    var $As = $lists.children('a');

    // Disable A link that has ul
    $As.on('click', function(e) {
        e.preventDefault()
    });

    // delegate accordian menu events from the parent nav
    $nav.on('click', function(e) {

        var target = e.target;
        var $parentLi = $(target).closest('li'); // closest, insead of parent, so it still works when click on i icons
        if (!$parentLi.length) return; // return if doesn't click on li
        var $subUl = $parentLi.children('ul');


        // let depth = $subUl.parents().length; // but some li has no sub ul, so...
        var depth = $parentLi.parents().length + 1;

        // filter out all elements (except target) at current depth or greater
        var allAtDepth = $nav.find('ul').filter(function () {
            if ($(this).parents().length >= depth && this !== $subUl.get(0)) {
                return true;
            }
            return false;
        });
        allAtDepth.slideUp(slideTime).closest('li').removeClass('open');

        // Toggle target
        if ($parentLi.has('ul').length) {
            $parentLi.toggleClass('open');
        }
        $subUl.stop().slideToggle(slideTime);

    });

})(jQuery);
